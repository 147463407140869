import React from "react";

const SmileIcon = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path d="M15.776 4.987c-6.187 0-11.205 5.033-11.205 11.221s5.017 11.221 11.205 11.221c6.204 0 11.237-5.033 11.237-11.221s-5.033-11.221-11.237-11.221zM10.983 11.399c0.882 0 1.603 0.721 1.603 1.603s-0.721 1.603-1.603 1.603c-0.882 0-1.603-0.721-1.603-1.603s0.721-1.603 1.603-1.603zM15.792 22.62c-2.982 0-5.466-2.052-6.187-4.809h12.375c-0.721 2.757-3.206 4.809-6.187 4.809zM20.601 14.605c-0.882 0-1.603-0.721-1.603-1.603s0.721-1.603 1.603-1.603c0.882 0 1.603 0.721 1.603 1.603s-0.721 1.603-1.603 1.603z"></path>
    </svg>
  );
};

export default SmileIcon;
