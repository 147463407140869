import React from "react";

const DollarIcon = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path d="M18.667 2.667h-13.333v26.667h21.333v-18.667l-8-8zM20 16h-5.333v1.333h5.333v6.667h-2.667v1.333h-2.667v-1.333h-2.667v-2.667h5.333v-1.333h-5.333v-6.667h2.667v-1.333h2.667v1.333h2.667v2.667zM17.333 10.667v-6l6 6h-6z"></path>
    </svg>
  );
};

export default DollarIcon;
