import common from "translations/common/en.json";
import home from "translations/home/en.json";
import privacyPolicy from "translations/privacyPolicy/en.json";
import imprint from "translations/imprint/en.json";
import termsOfService from "translations/termsOfService/en.json";
import blog from "translations/blog/en.json";

export const translationsEN = {
  common,
  home,
  privacyPolicy,
  imprint,
  termsOfService,
  blog,
};
