import { gql } from "apollo-boost";

export const AuthorQuery = gql`
  query AuthorItem($id: ID!, $language: String) {
    AuthorItem(id: $id, language: $language) {
      content {
        avatar {
          filename
        }
        boilerplate
        name
      }
    }
  }
`;

export default AuthorQuery;
