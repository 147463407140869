import React, { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.js";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import {
  BLOG_LOAD_MORE_STEP,
  BLOG_CARD_PARAGRAPH_LIMIT,
} from "constants/settings";
import PropTypes from "prop-types";
import { basicAppRoutes } from "constants/baseAppRoutes";
import { Link } from "react-router-dom";
import useLoadMore from "hooks/useLoadMore";
import blogCategories from "constants/blogCategories";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { truncateText } from "utils/truncateText";
import { useRoutePath } from "hooks/useRoutePath";
import { generateUrlSlug } from "utils/generateUrlSlug";
import { baseAppRoutesConfig } from "constants/baseAppRoutes";

const useStyles = makeStyles(sectionPillsStyle);
const blogCategoryValues = Object.values(blogCategories);
const { pathParams } = baseAppRoutesConfig;

export default function SectionPills(props) {
  const { t } = useTranslation(Namespaces.Blog);
  const classes = useStyles();
  const { posts } = props;
  const { category = blogCategories.All } = useParams();
  const history = useHistory();
  const routePath = useRoutePath();

  const [currentCategory, setCurrentCategory] = useState(category);

  const currentCategoryIndex = useMemo(
    () => blogCategoryValues.indexOf(currentCategory),
    [currentCategory],
  );

  useEffect(() => {
    if (category === blogCategories.All) return;
    if (currentCategoryIndex === -1) {
      setCurrentCategory(blogCategories.All);
      history.push(routePath(basicAppRoutes.BLOG));
      return;
    }
  }, [category, currentCategoryIndex, history, routePath]);

  const currentPosts = useMemo(() => {
    if (currentCategory === blogCategories.All) return posts;
    return posts.filter((p) => p.content.category.includes(currentCategory));
  }, [currentCategory, posts]);

  const [blogPostsLimit, visiblePosts, loadMore] = useLoadMore({
    postsLength: currentPosts.length,
    step: BLOG_LOAD_MORE_STEP,
    inARow: 2,
  });

  const categoriesTabs = Object.values(blogCategories).map((c) => {
    return {
      tabButton: t(`categories.${c}`),
      tabSlug: c,
    };
  });

  const isLoadMoreVisible =
    currentPosts.length + blogPostsLimit > visiblePosts + blogPostsLimit;

  const onNavPillClick = (categorySlug) => {
    if (categorySlug === blogCategories.All) {
      history.push(routePath(basicAppRoutes.BLOG));
    } else {
      history.push(
        routePath(basicAppRoutes.BLOG_WITH_CATEGORY, {
          ":category": categorySlug,
        }),
      );
    }
    setCurrentCategory(categorySlug);
  };

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={7} className={classes.textCenter}>
          <NavPills
            active={currentCategoryIndex}
            color="secondary"
            alignCenter
            tabs={categoriesTabs}
            onNavPillClick={onNavPillClick}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        {currentPosts.slice(0, visiblePosts).map((post) => {
          const { id, content } = post;
          const { image, title } = content;
          const intro = truncateText(
            post.content.intro,
            BLOG_CARD_PARAGRAPH_LIMIT,
          );
          const link = routePath(basicAppRoutes.BLOG_POST, {
            [pathParams.postSlug]: generateUrlSlug(`${id} ${title}`),
          });
          const category = post.content.category
            .map((c) => t(`categories.${c}`))
            .join(", ");

          return (
            <GridItem
              key={id}
              xs={12}
              sm={6}
              md={6}
              className={classes.gridItem}
            >
              <Card
                raised
                background
                style={{
                  backgroundImage: "url(" + image + ")",
                }}
              >
                <CardBody background className={classes.cardBody}>
                  <div>
                    <h6 className={classes.category}>{category}</h6>
                    <Link to={link}>
                      <h3 className={classes.cardTitle}>{title}</h3>
                    </Link>
                    <p className={classes.category}>{intro}</p>
                  </div>
                  <div>
                    <Link to={link} className={classes.block}>
                      <Button round color="primary">
                        <FormatAlignLeft className={classes.icons} />
                        {t("posts.post.read_article")}
                      </Button>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
      {isLoadMoreVisible && (
        <GridContainer className="align-center">
          <div className={classes.loadMoreBtnWrap}>
            <Button onClick={loadMore} round color="secondary">
              {t("posts.load_more")}
            </Button>
          </div>
        </GridContainer>
      )}
    </div>
  );
}

SectionPills.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
};
