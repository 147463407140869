import React from "react";
import logoBlackSrc from "assets/img/logo-black.svg";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/components/liilixLogoStyle";

const useStyles = makeStyles(styles);

const LiilixLogo = () => {
  const classes = useStyles();
  return <img src={logoBlackSrc} className={classes.logo} alt="liilix" />;
};

export default LiilixLogo;
