import React from "react";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
import styles from "assets/jss/material-kit-pro-react/components/languageButtonStyle";
import { makeStyles } from "@material-ui/core/styles";
import { Languages } from "constants/appLanguages";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";

const useHeadersStyles = makeStyles(headersStyle);
const useStyles = makeStyles(styles);

const LanguageButton = () => {
  const headersClasses = useHeadersStyles();
  const classes = useStyles();
  const { t, i18n } = useTranslation(Namespaces.Common);

  const language = i18n.languages[0] ?? "";

  const handleChangeLang = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <CustomDropdown
      noLiPadding
      navDropdown
      buttonText={language.toUpperCase()}
      buttonProps={{
        className: headersClasses.navLink,
        color: "transparent",
      }}
      dropdownList={Object.values(Languages).map((lng) => (
        <div
          className={classes.lng}
          key={lng}
          onClick={() => handleChangeLang(lng)}
        >
          {t(`languages.${lng}`, lng.toUpperCase())}
        </div>
      ))}
    />
  );
};

export default LanguageButton;
