import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import bg from "assets/img/airpods.jpg";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { basicNavAnchors } from "constants/basicNavAnchors";

const useStyles = makeStyles(contactsStyle);

export default function SectionContacts({ ...rest }) {
  const [checked, setChecked] = React.useState([]);

  const { t } = useTranslation(Namespaces.Home);

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const classes = useStyles();

  return (
    <div className="cd-section" {...rest} id={basicNavAnchors.CONTACT_US}>
      <div
        className={classes.contacts + " " + classes.section}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <h2 className={classes.title}>{t("section.contact_us.title")}</h2>
              <h5 className={classes.description}>
                {t("section.contact_us.description")}
              </h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} className={classes.mlAuto}>
              <Card className={classes.card1}>
                <form>
                  <CardHeader
                    contact
                    color="secondary"
                    className={
                      classes.textCenter + " " + classes.customCardHeader
                    }
                  >
                    <h4 className={classes.cardTitle}>
                      {t("section.contact_us.form.title")}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText={t("section.contact_us.form.first_name")}
                          id="first"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText={t("section.contact_us.form.last_name")}
                          id="last"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <CustomInput
                      labelText={t("section.contact_us.form.email")}
                      id="email-address"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    <CustomInput
                      labelText={t("section.contact_us.form.message")}
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentBetween}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(1)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label={t("section.contact_us.form.recaptcha_text")}
                    />
                    <Button color="primary" className={classes.pullRight}>
                      {t("section.contact_us.form.submit")}
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
