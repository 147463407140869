import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import styles from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";
import phoneSrc from "assets/img/app.png";
import InviteIcon from "components/svg-icons/InviteIcon";
import LotusIcon from "components/svg-icons/LotusIcon";
import StoreButtons from "components/StoreButtons/StoreButtons";
import TabletIcon from "components/svg-icons/TabletIcon";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { basicNavAnchors } from "constants/basicNavAnchors";

const useStyles = makeStyles(styles);

const SectionDownload = () => {
  const classes = useStyles();
  const { t } = useTranslation(Namespaces.Home);

  return (
    <div className={classes.download} id={basicNavAnchors.DOWNLOAD}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <div className={classes.phoneContainer}>
            <img src={phoneSrc} alt="..." />
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} className={classes.iconRefinements}>
          <h2 className={classes.title}>{t("section.download.title")}</h2>
          <InfoArea
            className={classNames(classes.infoArea, classes.opticalSmallerIcon)}
            icon={TabletIcon}
            title={t("section.download.info_areas.info1.title")}
            description={t("section.download.info_areas.info1.description")}
            iconColor="secondary"
          />
          <InfoArea
            className={classes.infoArea}
            icon={InviteIcon}
            title={t("section.download.info_areas.info2.title")}
            description={t("section.download.info_areas.info2.description")}
            iconColor="secondary"
          />
          <InfoArea
            className={classNames(classes.infoArea, classes.opticalBiggerIcon)}
            icon={LotusIcon}
            title={t("section.download.info_areas.info3.title")}
            description={t("section.download.info_areas.info3.description")}
            iconColor="secondary"
          />
          <StoreButtons
            rootClass={classes.centerIconOnMobile}
            appStoreLink={t("section.download.app_links.apple_store")}
            googlePlayLink={t("section.download.app_links.google_play")}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default SectionDownload;
