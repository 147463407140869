import {
  container,
  main,
  lTextColors,
} from "assets/jss/material-kit-pro-react.js";

const infoPagesStyles = {
  container: {
    color: lTextColors[0],
    ...container,
    zIndex: "2",
  },
  main: {
    ...main,
    paddingTop: "200px",
  },
  pageContent: {
    paddingBottom: "200px",
  },
  pageTitle: {
    fontWeight: "600",
    fontSize: "30px",
    textAlign: "center",
  },
  title: {
    padding: "80px 0",
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "center",
  },
  pb80: {
    paddingBottom: "80px",
  },
};

export default infoPagesStyles;
