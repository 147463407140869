import {
  title,
  firstSectionGutter,
  lTextColors,
} from "assets/jss/material-kit-pro-react.js";

const serviceStyle = (theme) => ({
  section: {
    ...firstSectionGutter,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "70px 0",
    },
  },
  title: {
    ...title,
    marginTop: "0",
    minHeight: "32px",
    textDecoration: "none",
    fontSize: "27px",
    color: lTextColors[0],
  },
  description: {
    color: lTextColors[1],
    fontSize: "17px",
    fontWeight: 500,
    marginTop: "37px",
  },
  infoArea: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "-50px",
    },
  },
});

export default serviceStyle;
