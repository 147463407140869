import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import styles from "assets/jss/material-kit-pro-react/views/landingPageSections/serviceStyle.js";
import HeadphonesIcon from "components/svg-icons/HeadphonesIcon";
import DollarIcon from "components/svg-icons/DollarIcon";
import SmileIcon from "components/svg-icons/SmileIcon";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { basicNavAnchors } from "constants/basicNavAnchors";

const useStyles = makeStyles(styles);

export default function SectionService() {
  const classes = useStyles();
  const { t } = useTranslation(Namespaces.Home);

  return (
    <div className={classes.section} id={basicNavAnchors.SERVICE}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>{t("section.service.title")}</h2>
          <h5 className={classes.description}>
            {t("section.service.description")}
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title={t("section.service.info_areas.info1.title")}
              description={t("section.service.info_areas.info1.description")}
              icon={HeadphonesIcon}
              iconColor="secondary"
              vertical
              className={classes.infoArea}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title={t("section.service.info_areas.info2.title")}
              description={t("section.service.info_areas.info2.description")}
              icon={DollarIcon}
              iconColor="secondary"
              vertical
              className={classes.infoArea}
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title={t("section.service.info_areas.info3.title")}
              description={t("section.service.info_areas.info3.description")}
              icon={SmileIcon}
              iconColor="secondary"
              vertical
              className={classes.infoArea}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
