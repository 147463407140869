import { createMuiTheme } from "@material-ui/core";
import {
  whiteColor,
  primaryColor,
  secondaryColor,
} from "assets/jss/material-kit-pro-react.js";

export const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor[0],
      contrastText: whiteColor,
    },

    secondary: {
      main: secondaryColor[0],
      contrastText: whiteColor,
    },
  },
});
