import { useState, useMemo, useEffect } from "react";

const useLoadMore = ({ postsLength, step, inARow }) => {
  const blogPostsLimit = useMemo(() => {
    if (
      postsLength > inARow &&
      postsLength < step &&
      postsLength % inARow !== 0
    ) {
      return postsLength - (postsLength % inARow);
    }
    return step;
  }, [postsLength, step, inARow]);

  const [visiblePosts, setVisiblePosts] = useState(step);

  useEffect(() => {
    setVisiblePosts(blogPostsLimit);
  }, [blogPostsLimit]);

  const loadMore = () => {
    setVisiblePosts((s) => {
      if (s + blogPostsLimit < postsLength + blogPostsLimit)
        return (s += blogPostsLimit);
      return s;
    });
  };

  return [blogPostsLimit, visiblePosts, loadMore];
};

export default useLoadMore;
