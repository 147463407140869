import React from "react";

const InviteIcon = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path d="M11.922 18.248c3.137 0 9.412 1.556 9.412 4.636v3.469h-18.824v-3.469c0-3.080 6.274-4.636 9.412-4.636z"></path>
      <path d="M16.627 11.765c0 2.686-2.107 4.863-4.706 4.863s-4.706-2.177-4.706-4.863c0-2.686 2.107-4.863 4.706-4.863s4.706 2.177 4.706 4.863z"></path>
      <path d="M19.765 16.627v-1.621h3.137v-3.242h1.569v3.242h3.137v1.621h-3.137v3.242h-1.569v-3.242z"></path>
    </svg>
  );
};

export default InviteIcon;
