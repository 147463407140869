import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
// import Parallax from "components/Parallax/Parallax.js";
import SectionPills from "./Sections/SectionPills.js";
import SectionInterested from "./Sections/SectionInterested.js";
import LHeaderLinks from "components/LHeaderLinks/LHeaderLinks";
import LiilixLogo from "components/LiilixLogo/LiilixLogo";
import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import LFooter from "components/LFooter/LFooter.js";
import { Namespaces } from "i18n";
import { Languages } from "constants/appLanguages";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import CircularProgress from "@material-ui/core/CircularProgress";
import cx from "classnames";
import PostsQuery from "gql/queries/PostsQuery";
import usePrevious from "hooks/usePrevious";

const useStyles = makeStyles(blogPostsPageStyle);

export default function BlogPostsPage() {
  const classes = useStyles();
  const { t, i18n } = useTranslation(Namespaces.Blog);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const currentLang = i18n.language.split("-")[0];
  const queryLang = currentLang === Languages.De ? "" : `${currentLang}/*`;

  const posts = useQuery(PostsQuery, {
    variables: { starts_with: queryLang },
  });

  const isFetching = posts.loading;

  const allPosts = posts.data?.PostItems.items || [];
  const topPosts = allPosts.filter((p) => p.content.top_content === true);

  const prevLang = usePrevious(i18n.language);

  useEffect(() => {
    if (prevLang !== i18n.language) {
      posts.refetch();
    }
  }, [i18n.language, posts, prevLang]);

  return (
    <div>
      <Header
        fixed
        brand={<LiilixLogo />}
        color="transparentBlack"
        changeColorOnScroll={{
          height: 40,
          color: "white",
        }}
        links={<LHeaderLinks />}
      />
      {/* <Parallax
        className={classes.hero}
        id="parallax"
        image={require("assets/img/blog-posts-bg.png")}
      ></Parallax> */}
      <div
        className={classes.hero}
        style={{
          background:
            "url(" +
            require("assets/img/blog-posts-bg.png") +
            ") no-repeat center / cover",
        }}
      ></div>
      <div className={classes.main}>
        <div className={classes.container}>
          {isFetching ? (
            <div className={cx(classes.loader, "align-center")}>
              <CircularProgress color="primary" />
            </div>
          ) : allPosts.length > 0 ? (
            <>
              <SectionPills posts={allPosts} />
              {topPosts.length > 0 ? (
                <SectionInterested topPosts={topPosts} />
              ) : null}
            </>
          ) : (
            <div className={classes.noPosts}>{t("posts.empty")}</div>
          )}
        </div>
      </div>
      <LFooter />
    </div>
  );
}
