import { gql } from "apollo-boost";

const PostsQuery = gql`
  query PostItems($starts_with: String) {
    PostItems(starts_with: $starts_with) {
      items {
        id
        content {
          image
          intro
          title
          top_content
          long_text
          category
        }
      }
    }
  }
`;

export default PostsQuery;
