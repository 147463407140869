import React from "react";

const TabletIcon = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path d="M22.667 16c-3.68 0-6.667 2.987-6.667 6.667s2.987 6.667 6.667 6.667c3.68 0 6.667-2.987 6.667-6.667s-2.987-6.667-6.667-6.667zM24.867 25.8l-2.867-2.867v-4.267h1.333v3.72l2.467 2.467-0.933 0.947zM26.667 4h-6.907c-0.56-1.547-2.027-2.667-3.76-2.667s-3.2 1.12-3.76 2.667h-6.907v25.333h10.813c-0.787-0.76-1.427-1.667-1.893-2.667h-6.253v-20h2.667v4h10.667v-4h2.667v6.773c0.947 0.133 1.84 0.413 2.667 0.8v-10.24zM16 6.667c-0.733 0-1.333-0.6-1.333-1.333s0.6-1.333 1.333-1.333c0.733 0 1.333 0.6 1.333 1.333s-0.6 1.333-1.333 1.333z"></path>
    </svg>
  );
};

export default TabletIcon;
