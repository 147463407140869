import React from "react";
import appStoreIconSrc from "assets/img/app_store.svg";
import googlePlayIconSrc from "assets/img/google_play.svg";
import styles from "assets/jss/material-kit-pro-react/components/storeButtonsStyle";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";

const useStyles = makeStyles(styles);

const StoreButtons = (props) => {
  const classes = useStyles();
  const { rootClass, appStoreLink, googlePlayLink } = props;

  return (
    <div className={classNames(classes.storeButtons, rootClass)}>
      {appStoreLink && (
        <a
          href={appStoreLink}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.storeButton}
        >
          <img src={appStoreIconSrc} alt="App Store"></img>
        </a>
      )}
      {googlePlayLink && (
        <a
          href={googlePlayLink}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.storeButton}
        >
          <img src={googlePlayIconSrc} alt="Google Play"></img>
        </a>
      )}
    </div>
  );
};

StoreButtons.propTypes = {
  rootClass: PropTypes.string,
  appStoreLink: PropTypes.string,
  googlePlayLink: PropTypes.string,
};

export default StoreButtons;
