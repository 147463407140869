import {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  blackColor,
  whiteColor,
  hexToRgb,
  sectionGutter,
  lTextColors,
} from "assets/jss/material-kit-pro-react.js";

const features = (theme) => ({
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  features1: {
    textAlign: "center",
    padding: "80px 0",
  },
  features2: {
    padding: "80px 0",
  },
  features3: {
    padding: "80px 0",
    "& $phoneContainer": {
      maxWidth: "220px",
      margin: "0 auto",
    },
  },
  download: {
    ...sectionGutter,
    "& $phoneContainer": {
      maxWidth: "420px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "350px",
        "& img": {
          transform: "rotate3d(2, 1, 3, 10deg) translate(50%, -30px)",
          marginLeft: "-160px",
        },
      },
    },
    "& $title": {
      fontSize: "24px",
      color: lTextColors[0],
    },
  },
  features4: {
    padding: "80px 0",
    "& $phoneContainer": {
      maxWidth: "260px",
      margin: "60px auto 0",
    },
  },
  features5: {
    padding: "80px 0",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    "& $title": {
      marginBottom: "30px",
    },
    "& $title,& $container": {
      position: "relative",
      zIndex: "2",
      color: whiteColor,
    },
    "&:after": {
      background: "rgba(" + hexToRgb(blackColor) + ",0.55)",
      position: "absolute",
      width: "100%",
      height: "100%",
      content: "''",
      zIndex: "0",
      left: "0px",
      top: "0px",
    },
    "& $container": {
      "& $gridContainer:last-child": {
        "& $gridItem": {
          borderBottom: "0",
        },
      },
      "& $gridItem": {
        border: "1px solid rgba(" + hexToRgb(whiteColor) + ", 0.35)",
        borderTop: "0",
        borderLeft: "0",
        "&:last-child": {
          borderRight: "0",
        },
      },
    },
    "& $infoArea5": {
      textAlign: "center",
      maxWidth: "310px",
      minHeight: "320px",
      "& h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
        color: whiteColor,
      },
    },
  },
  gridContainer: {},
  gridItem: {},
  textCenter: {
    textAlign: "center",
  },
  phoneContainer: {
    "& img": {
      width: "100%",
    },
  },
  infoArea: {
    maxWidth: "none",
    margin: "0 auto",
    padding: "10px 0 0px",
  },
  infoArea5: {},
  iconRefinements: {
    "& svg": {
      height: "40px !important",
      width: "40px !important",
    },
  },
  opticalBiggerIcon: {
    "& svg": {
      height: "50px !important",
      width: "50px !important",
      marginLeft: "-8px",
      marginRight: "-2px",
    },
  },
  opticalSmallerIcon: {
    "& svg": {
      height: "35px !important",
      width: "35px !important",
      marginRight: "5px",
    },
  },
  centerIconOnMobile: {
    "@media (max-width: 960px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
});

export default features;
