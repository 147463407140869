import styles from "assets/jss/material-kit-pro-react/views/sectionsSections/lInfoStyle.js";
import StoreButtons from "components/StoreButtons/StoreButtons";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const SectionInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation(Namespaces.Home);

  return (
    <div className={classes.infoSection}>
      <h3 className={classes.text}>{t("section.info.title")}</h3>
      <StoreButtons
        rootClass={classes.centerIcon}
        appStoreLink={t("section.info.app_links.apple_store")}
        googlePlayLink={t("section.info.app_links.google_play")}
      />
    </div>
  );
};

export default SectionInfo;
