import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import sectionBlogInfoStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionBlogInfoStyle.js";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import AuthorQuery from "gql/queries/AuthorQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import usePrevious from "hooks/usePrevious";

const useStyles = makeStyles(sectionBlogInfoStyle);

export default function SectionBlogInfo(props) {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const { post } = props;

  const authorId = post.content.author.id;

  const author = useQuery(AuthorQuery, {
    variables: { id: authorId, language: i18n.language },
  });

  const isFetching = author.loading;
  const preparedAuthor = author.data?.AuthorItem || {};

  const authorName = preparedAuthor.content?.name || "";
  const authorImg = preparedAuthor.content?.avatar.filename || "";
  const authorQuote = preparedAuthor.content?.boilerplate || "";

  const prevLang = usePrevious(i18n.language);

  useEffect(() => {
    if (prevLang !== i18n.language) {
      author.refetch();
    }
  }, [i18n.language, author, prevLang]);

  return (
    <div className={classes.section}>
      {isFetching ? (
        <div className="align-center">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          {!!authorName && (
            <GridContainer justify="center">
              <GridItem xs={12} sm={8} md={6}>
                <hr />
                <Card plain profile className={classes.card}>
                  <GridContainer>
                    {!!authorImg && (
                      <GridItem xs={12} sm={3} md={3}>
                        <CardAvatar plain profile>
                          <img src={authorImg} alt={authorName} />
                        </CardAvatar>
                      </GridItem>
                    )}
                    {!!authorQuote && (
                      <GridItem xs={12} sm={9} md={9}>
                        <h4 className={classes.cardTitle}>{authorName}</h4>
                        <p className={classes.description}>{authorQuote}</p>
                      </GridItem>
                    )}
                  </GridContainer>
                </Card>
              </GridItem>
            </GridContainer>
          )}
        </>
      )}
    </div>
  );
}

SectionBlogInfo.propTypes = {
  post: PropTypes.object,
};
