import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import bgSrc from "assets/img/pricing-bg.jpg";
import styles from "assets/jss/material-kit-pro-react/views/sectionsSections/lPricingStyle.js";
import classNames from "classnames";
import { Namespaces } from "i18n";
import { useTranslation, Trans } from "react-i18next";

const useStyles = makeStyles(styles);

const SectionPricing = () => {
  const classes = useStyles();

  const { t } = useTranslation(Namespaces.Home);

  const [currentWindowWidth, setCurrentWindowWidth] = React.useState(
    window.innerWidth,
  );

  const handleWindowResize = React.useCallback(() => {
    setCurrentWindowWidth(window.innerWidth);
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const innerClasses = classNames({
    [classes.pricingInner]: true,
    [classes.container]: currentWindowWidth > 2000,
  });

  const imgClasses = classNames({
    [classes.img]: true,
    [classes.hLarge]: currentWindowWidth > 2000,
  });

  return (
    <div className={classes.pricingSection}>
      <div className={innerClasses}>
        <div
          style={{ backgroundImage: `url(${bgSrc})` }}
          className={imgClasses}
        ></div>
        <GridContainer className={classes.priceBlock}>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.alignCenter}
          >
            <Card pricing plain>
              <CardBody pricing plain>
                <h3 className={classes.title}>
                  {t("section.pricing.pricing_cards.card1.title")}
                </h3>
                <p className={classes.text}>
                  {t("section.pricing.pricing_cards.card1.description")}
                </p>
                <div>
                  <div className={classes.priceMain}>
                    <span>
                      {t("section.pricing.pricing_cards.card1.price")}
                    </span>
                    &nbsp;{t("section.pricing.pricing_cards.card1.currency")}
                  </div>
                  <div className={classes.priceList}>
                    <div className={classes.priceListItem}>
                      <Trans
                        defaults={t(
                          "section.pricing.pricing_cards.card1.features.feature1",
                        )}
                        components={{ b: <b /> }}
                      />
                    </div>
                    <div className={classes.priceListItem}>
                      <Trans
                        defaults={t(
                          "section.pricing.pricing_cards.card1.features.feature2",
                        )}
                        components={{ b: <b /> }}
                      />
                    </div>
                    <div className={classes.priceListItem}>
                      <Trans
                        defaults={t(
                          "section.pricing.pricing_cards.card1.features.feature3",
                        )}
                        components={{ b: <b /> }}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default SectionPricing;
