import common from "translations/common/de.json";
import home from "translations/home/de.json";
import privacyPolicy from "translations/privacyPolicy/de.json";
import imprint from "translations/imprint/de.json";
import termsOfService from "translations/termsOfService/de.json";
import blog from "translations/blog/de.json";

export const translationsDE = {
  common,
  home,
  privacyPolicy,
  imprint,
  termsOfService,
  blog,
};
