import { Button } from "@material-ui/core";
import React from "react";
import styles from "assets/jss/material-kit-pro-react/components/downloadButtonStyle";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import GetAppIcon from "@material-ui/icons/GetApp";
import classNames from "classnames";

const useStyles = makeStyles(styles);

const DownloadButton = (props) => {
  const classes = useStyles();
  const { link, text, rootStyle, iconStyle, btnStyle, textStyle } = props;

  return (
    <div className={classNames(classes.dlBlock, rootStyle)}>
      <a download href={link}>
        <Button className={(classes.btn, btnStyle)}>
          <GetAppIcon className={classNames(classes.icon, iconStyle)} />
          <span className={classNames(classes.text, textStyle)}>{text}</span>
        </Button>
      </a>
    </div>
  );
};

DownloadButton.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  rootStyle: PropTypes.string,
  iconStyle: PropTypes.string,
  btnStyle: PropTypes.string,
  textStyle: PropTypes.string,
};

export default DownloadButton;
