import { gql } from "apollo-boost";

const LatestPostsQuery = gql`
  query PostItems($starts_with: String, $per_page: Int = 100) {
    PostItems(starts_with: $starts_with, per_page: $per_page) {
      items {
        id
        content {
          image
          intro
          title
          category
          author {
            id
            name
          }
        }
      }
    }
  }
`;

export default LatestPostsQuery;
