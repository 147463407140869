import { container, title } from "assets/jss/material-kit-pro-react.js";

import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const sectionTextStyle = {
  container,
  title: {
    ...title,
    color: "rgba(60, 72, 88, 1)",
    fontSize: "33px",
    fontWeight: "600",
  },
  date: {
    margin: "40px 0",

    "& span": {
      fontSize: "1.1rem",
      fontWeight: "600",
      lineHeight: "1.5em",
      color: "rgba(60, 72, 88, 1)",
    },
  },
  section: {
    paddingBottom: "0",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "150px 0 70px 0",
    // "& p": {
    //   fontSize: "1.188rem",
    //   lineHeight: "1.5em",
    //   color: grayColor[15],
    //   marginBottom: "30px",
    // },
  },
  imageSection: {
    margin: "70px 0",
  },
  quoteText: {
    fontSize: "1.5rem !important",
  },
  customImageStyle: {
    margin: "0 auto",
    display: "block",
  },
  text: {
    lineHeight: "1.9rem",
    color: "rgba(60, 72, 88, 1)",
    fontSize: "1.1rem",
    fontWeight: 400,
    "& p": {
      margin: 0,
      fontSize: "inherit",
      lineHeight: "inherit",
    },
  },
  authorName: {
    color: "rgba(60, 72, 88, 1) !important",
  },
  desc: {},
  ...imagesStyles,
};

export default sectionTextStyle;
