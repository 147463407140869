const extractIdFromSlug = (slug) => {
  return (
    slug
      .trim()
      .split(/[\D]+/)
      .filter((v) => v)[0] || ""
  );
};

export default extractIdFromSlug;
