import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cx from "classnames";
import { truncateText } from "utils/truncateText";
import { BLOG_CARD_PARAGRAPH_LIMIT } from "constants/settings";
import { basicAppRoutes } from "constants/baseAppRoutes";
import { Link } from "react-router-dom";
import { getCategoryStyle } from "utils/getCategoryStyle";
import { Fragment } from "react";
import { useRoutePath } from "hooks/useRoutePath";
import { generateUrlSlug } from "utils/generateUrlSlug";
import { baseAppRoutesConfig } from "constants/baseAppRoutes";

const useStyles = makeStyles(blogsStyle);
const { pathParams } = baseAppRoutesConfig;

export default function SectionBlogs(props) {
  const classes = useStyles();
  const { t } = useTranslation(Namespaces.Blog);
  const { latestsPosts } = props;
  const routePath = useRoutePath();

  const [currentWindowWidth, setCurrentWindowWidth] = React.useState(
    window.innerWidth,
  );

  const handleWindowResize = React.useCallback(() => {
    setCurrentWindowWidth(window.innerWidth);
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          className={cx(classes.mlAuto, classes.mrAuto)}
        >
          <h2 className={classes.title}>{t("latest_posts.title")}</h2>
          <br />
          {latestsPosts.map((post, index) => {
            const { id, content } = post;
            const { image, title } = content;
            const intro = truncateText(
              post.content.intro,
              BLOG_CARD_PARAGRAPH_LIMIT,
            );
            const author = content.author.name;
            const link = routePath(basicAppRoutes.BLOG_POST, {
              [pathParams.postSlug]: generateUrlSlug(`${id} ${title}`),
            });
            const category = content.category.map((c, i) => (
              <span key={i} style={getCategoryStyle(c)}>
                {t(`categories.${c}`)}
              </span>
            ));

            const isSm = currentWindowWidth < 960;
            const isRowReversed = !isSm && (index + 1) % 2 === 0;

            const renderImage = () => (
              <GridItem xs={12} sm={12} md={5}>
                <CardHeader image plain>
                  <Link to={link}>
                    <img src={image} alt={title} />
                  </Link>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${image})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
              </GridItem>
            );

            return (
              <Card key={id} plain blog className={classes.card}>
                <GridContainer>
                  {!isRowReversed && renderImage()}
                  <GridItem xs={12} sm={12} md={7}>
                    <Info>
                      <h6
                        className={cx(classes.cardCategory, {
                          [classes.mt25]: isSm,
                        })}
                      >
                        {category.map((c, i) => {
                          if (category.indexOf(c) !== category.length - 1)
                            return <Fragment key={i}>{c}, </Fragment>;
                          return c;
                        })}
                      </h6>
                    </Info>
                    <h3 className={classes.cardTitle}>
                      <Link to={link}>{title}</Link>
                    </h3>
                    <p className={classes.description1}>
                      {intro} <Link to={link}>{t("posts.post.read_more")}</Link>
                    </p>
                    <p className={classes.author}>
                      {t("posts.post.author", {
                        name: author,
                      })}
                    </p>
                  </GridItem>
                  {isRowReversed && renderImage()}
                </GridContainer>
              </Card>
            );
          })}
        </GridItem>
      </GridContainer>
    </div>
  );
}

SectionBlogs.propTypes = {
  latestsPosts: PropTypes.arrayOf(PropTypes.object),
};
