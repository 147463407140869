import { blackColor, whiteColor } from "assets/jss/material-kit-pro-react.js";

const languageButtonStyle = {
  lng: {
    color: blackColor,
    padding: "5px 10px",
    "&:hover": {
      color: whiteColor,
    },
  },
};

export default languageButtonStyle;
