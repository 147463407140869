import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import styles from "assets/jss/material-kit-pro-react/components/agreementTextStyles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const AgreementText = (props) => {
  const { paragraphs, customLinks } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Trans
        components={{
          b: <b />,
          u: <u />,
          i: <i />,
          ...customLinks,
        }}
      >
        {Array.isArray(paragraphs) ? (
          paragraphs.map((p, key) => <p key={key}>{p}</p>)
        ) : (
          <p>{paragraphs}</p>
        )}
      </Trans>
    </div>
  );
};

AgreementText.propTypes = {
  paragraphs: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
    .isRequired,
  customLinks: PropTypes.object,
};

export default AgreementText;
