import common from "translations/common/ru.json";
import home from "translations/home/ru.json";
import privacyPolicy from "translations/privacyPolicy/ru.json";
import imprint from "translations/imprint/ru.json";
import termsOfService from "translations/termsOfService/ru.json";
import blog from "translations/blog/ru.json";

export const translationsRU = {
  common,
  home,
  privacyPolicy,
  imprint,
  termsOfService,
  blog,
};
