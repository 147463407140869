import {
  container,
  title,
  main,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

const landingPageStyle = {
  container: {
    color: whiteColor,
    ...container,
    zIndex: "2",
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: whiteColor,
    textDecoration: "none",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    ...main,
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
  loader: {
    padding: "320px 0 520px 0",
  },
};

export default landingPageStyle;
