import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { APP_DEFAULT_LANGUAGE } from "constants/settings";
import { translationsEN } from "translations/translationsEN";
import { translationsDE } from "translations/translationsDE";
import { translationsUK } from "translations/translationsUK";
import { translationsRU } from "translations/translationsRU";
import { Languages } from "constants/appLanguages";

const resources = {
  en: translationsEN,
  de: translationsDE,
  uk: translationsUK,
  ru: translationsRU,
};

export const Namespaces = {
  Common: "common",
  Home: "home",
  PrivacyPolicy: "privacyPolicy",
  Imprint: "imprint",
  TermsOfService: "termsOfService",
  Blog: "blog",
};

const createI18nInstance = ({ language }) => {
  const instance = i18next.createInstance();
  instance
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
      lng: language,
      fallbackLng: APP_DEFAULT_LANGUAGE,
      supportedLngs: Object.values(Languages),
      resources,
      ns: Object.values(Namespaces),
      load: "languageOnly",
      react: {
        wait: true,
        useSuspense: false,
        bindI18n: "languageChanged",
      },
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: [
          "path",
          "querystring",
          "cookie",
          "localStorage",
          "sessionStorage",
          "navigator",
          "htmlTag",
        ],
      },
    });

  return instance;
};

const i18n = createI18nInstance({});

export default i18n;
