import React from "react";
import { Helmet } from "react-helmet";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";

const LXMetaTags = () => {
  const { t, i18n } = useTranslation(Namespaces.Common);

  return (
    <Helmet htmlAttributes={{ lang: i18n.language }}>
      <title>{t("meta_tags.title")}</title>
      <meta name="twitter:card" content={t("meta_tags.twitter.card")} />
      <meta name="twitter:card" content={t("meta_tags.twitter.card2")} />
      <meta name="twitter:site" content={t("meta_tags.twitter.site")} />
      <meta name="twitter:creator" content={t("meta_tags.twitter.creator")} />
      <meta name="twitter:title" content={t("meta_tags.twitter.title")} />
      <meta
        name="twitter:description"
        content={t("meta_tags.twitter.description")}
      />
      <meta name="twitter:image" content={t("meta_tags.twitter.image")} />
      <meta name="twitter:url" content={t("meta_tags.twitter.url")} />
      <meta name="description" content={t("meta_tags.description")} />
      <meta name="keywords" content={t("meta_tags.keywords")} />
      <meta property="og:title" content={t("meta_tags.og.title")} />
      <meta property="og:url" content={t("meta_tags.og.url")} />
      <meta property="og:image" content={t("meta_tags.og.image")} />
      <meta property="og:description" content={t("meta_tags.og.description")} />
    </Helmet>
  );
};

export default LXMetaTags;
