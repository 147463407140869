import React from "react";
import styles from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import LanguageButton from "components/LanguageButton/LanguageButton";
import { basicAppRoutes } from "constants/baseAppRoutes";
import { basicNavAnchors } from "constants/basicNavAnchors";
import { Link } from "react-router-dom";
import { useRoutePath } from "hooks/useRoutePath";

const useStyles = makeStyles(styles);

const LHeaderLinks = () => {
  const classes = useStyles();
  const { t } = useTranslation(Namespaces.Common);
  const routePath = useRoutePath();
  const homeLink = routePath(basicAppRoutes.HOME);

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const isHome = window.location.pathname === homeLink;

  const smoothScroll = (e, target) => {
    if (isHome) {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i,
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        startScroll(target);
      }
    }
  };

  const startScroll = (href) => {
    const targetScroll = document.getElementById(href);
    const relativeVal =
      href !== basicNavAnchors.HOME
        ? document.getElementById(basicNavAnchors.HOME).offsetHeight
        : 0;
    scrollGo(
      document.documentElement,
      targetScroll.offsetTop + relativeVal,
      700,
    );
  };

  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 10;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const { SERVICE, DOWNLOAD, CONTACT_US } = basicNavAnchors;

  const serviceLink = `${homeLink}#${SERVICE}`;
  const downloadLink = `${homeLink}#${DOWNLOAD}`;
  const contactLink = `${homeLink}#${CONTACT_US}`;

  return (
    <div className={classes.collapse}>
      <List className={classNames(classes.list, classes.mlAuto)}>
        <ListItem className={classes.listItem}>
          <Link
            to={homeLink}
            className={classes.navLink}
            onClick={(e) => smoothScroll(e, basicNavAnchors.HOME)}
          >
            {t("navigation.home")}
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to={serviceLink}
            className={classes.navLink}
            onClick={(e) => smoothScroll(e, SERVICE)}
          >
            {t("navigation.service")}
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to={downloadLink}
            className={classes.navLink}
            onClick={(e) => smoothScroll(e, DOWNLOAD)}
          >
            {t("navigation.download")}
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link
            to={contactLink}
            className={classes.navLink}
            onClick={(e) => smoothScroll(e, CONTACT_US)}
          >
            {t("navigation.contact_us")}
          </Link>
        </ListItem>
      </List>
      <List className={classNames(classes.list, classes.mlAuto)}>
        <ListItem className={classes.listItem}>
          <a
            href={t("navigation.links.twitter")}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(classes.navLink, classes.navLinkJustIcon)}
          >
            <i className={"fab fa-twitter"} />
          </a>
        </ListItem>
        <ListItem className={classes.listItem}>
          <a
            href={t("navigation.links.facebook")}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(classes.navLink, classes.navLinkJustIcon)}
          >
            <i className={"fab fa-facebook"} />
          </a>
        </ListItem>
        <ListItem className={classes.listItem}>
          <a
            href={t("navigation.links.instagram")}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(classes.navLink, classes.navLinkJustIcon)}
          >
            <i className={"fab fa-instagram"} />
          </a>
        </ListItem>
      </List>
      <LanguageButton />
    </div>
  );
};

export default LHeaderLinks;
