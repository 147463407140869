import React from "react";

const LotusIcon = () => {
  return (
    <svg viewBox="0 0 32 32">
      <path d="M17.831 9.424c0 1.31-1.062 2.373-2.373 2.373s-2.373-1.062-2.373-2.373c0-1.31 1.062-2.373 2.373-2.373s2.373 1.062 2.373 2.373z"></path>
      <path d="M24.949 18.884v0c0-0.411-0.301-0.744-0.712-0.791-2.31-0.206-4.477-1.424-5.695-3.227-0.585-0.902-1.55-1.487-2.626-1.487h-0.791c-1.202 0-2.167 0.585-2.768 1.487-1.202 1.803-3.37 3.021-5.695 3.227-0.395 0.032-0.696 0.38-0.696 0.791v0c0 0.475 0.411 0.823 0.87 0.791 2.737-0.206 4.825-1.329 6.249-3.069v3.876l-4.303 1.851c-0.601 0.253-1.092 0.791-1.202 1.44-0.221 1.218 0.712 2.262 1.882 2.262h2.832v-0.395c0-1.534 1.234-2.768 2.768-2.768h3.164c0.221 0 0.395 0.174 0.395 0.395s-0.174 0.395-0.395 0.395h-3.164c-1.092 0-1.977 0.886-1.977 1.977v0.395h8.273c0.933 0 1.803-0.617 1.977-1.534 0.174-0.902-0.301-1.788-1.123-2.136l-4.382-1.867v-3.876c1.424 1.724 3.512 2.863 6.249 3.069 0.459 0.016 0.87-0.332 0.87-0.807z"></path>
    </svg>
  );
};

export default LotusIcon;
