import React, { useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import SectionText from "./Sections/SectionText.js";
import SectionBlogInfo from "./Sections/SectionBlogInfo.js";
import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";
import LHeaderLinks from "components/LHeaderLinks/LHeaderLinks";
import LiilixLogo from "components/LiilixLogo/LiilixLogo";
import LFooter from "components/LFooter/LFooter.js";
import PostQuery from "gql/queries/PostQuery";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import cx from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import SectionPostNotFound from "./Sections/SectionPostNotFound";
import extractIdFromSlug from "utils/extractIdFromSlug";
import { useHistory, useRouteMatch } from "react-router-dom";
import { basicAppRoutes } from "constants/baseAppRoutes";
import usePrevious from "hooks/usePrevious";
import { useRoutePath } from "hooks/useRoutePath";
import { generateUrlSlug } from "utils/generateUrlSlug";
import { baseAppRoutesConfig } from "constants/baseAppRoutes";

const useStyles = makeStyles(blogPostPageStyle);
const { pathParams } = baseAppRoutesConfig;

export default function BlogPostPage() {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { url_slug } = useParams();
  const blogPostId = extractIdFromSlug(url_slug);
  const history = useHistory();
  const match = useRouteMatch();
  const routePath = useRoutePath();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const post = useQuery(PostQuery, {
    variables: { id: blogPostId, language: i18n.language },
  });

  const isFetching = post.loading;
  const emptyPost = {};

  const preparedPost = post.data?.PostItem || emptyPost;

  const { id, content } = preparedPost;
  const title = content?.title;

  const i18nSlug = useMemo(() => {
    if (id && title) return generateUrlSlug(`${id} ${title}`);
    return "";
  }, [id, title]);

  const currentPageI18nPath = useMemo(
    () =>
      i18nSlug
        ? routePath(basicAppRoutes.BLOG_POST, {
            [pathParams.postSlug]: i18nSlug,
          })
        : undefined,
    [i18nSlug, routePath],
  );

  useEffect(() => {
    if (currentPageI18nPath && match.url !== currentPageI18nPath) {
      history.replace(currentPageI18nPath);
    }
  }, [i18nSlug, history, match.url, currentPageI18nPath]);

  const prevLang = usePrevious(i18n.language);

  useEffect(() => {
    if (prevLang !== i18n.language) {
      post.refetch();
    }
  }, [i18n.language, post, prevLang]);

  return (
    <div>
      <Header
        fixed
        brand={<LiilixLogo />}
        color="transparentBlack"
        changeColorOnScroll={{
          height: 40,
          color: "white",
        }}
        links={<LHeaderLinks />}
      />
      <div className={classes.main}>
        <div className={classes.container}>
          {isFetching ? (
            <div className={cx(classes.loader, "align-center")}>
              <CircularProgress color="primary" />
            </div>
          ) : preparedPost !== emptyPost ? (
            <>
              <SectionText post={preparedPost} />
              <SectionBlogInfo post={preparedPost} />
            </>
          ) : (
            <SectionPostNotFound />
          )}
        </div>
      </div>
      <LFooter />
    </div>
  );
}
