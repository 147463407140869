import {
  container,
  title,
  coloredShadow,
  description,
  mlAuto,
  mrAuto,
} from "assets/jss/material-kit-pro-react.js";

const blogsSection = {
  container,
  coloredShadow,
  mlAuto,
  mrAuto,
  description,
  blog: {
    padding: "50px 0",
  },
  cardCategory: {
    marginBottom: "0",
    marginTop: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "8px",
      lineHeight: "0",
    },
  },
  description1: {
    ...description,
    lineHeight: "1.8rem",
    color: "rgba(153, 153, 153, 1)",
    fontSize: "17px",
    fontWeight: "500",
  },
  author: {
    color: "rgba(0, 0, 0, 1)",
    textDecoration: "none",
    fontSize: "16px",
  },
  card: {
    marginBottom: "80px",
  },
  card4: {
    marginBottom: "60px",
    textAlign: "center",
  },
  section: {
    marginBottom: "45px",
  },
  title: {
    ...title,
    fontFamily: "Roboto Slab",
    color: "rgba(60, 72, 88, 1)",
    fontSize: "25px",
    marginBottom: "-35px",
  },
  cardTitle: {
    textDecoration: "none",
    fontWeight: "700",
    fontFamily: "Roboto Slab",
    marginTop: "5px",
    marginBottom: "0.75rem",
    minHeight: "auto",
    "& a": {
      color: "rgba(60, 72, 88, 1) !important",
      fontSize: "20px",
    },
  },
  mt25: {
    marginTop: "25px",
  },
};

export default blogsSection;
