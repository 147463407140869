import React from "react";
import { Redirect, Route, Router, Switch } from "react-router";
import { useTranslation } from "react-i18next";
import { createBrowserHistory } from "history";
import PropTypes from "prop-types";
import { Languages } from "constants/appLanguages";
import { baseAppRoutesConfig } from "constants/baseAppRoutes";

const hist = createBrowserHistory();
const { lang } = baseAppRoutesConfig.pathParams;

const LocalizedRouter = ({ children }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const preparedRouteLangs = Object.values(Languages).join("|");

  return (
    <Router history={hist}>
      <Switch>
        <Route path={`/${lang}(${language})`} render={() => children} />
        <Route
          path={`/${lang}(${preparedRouteLangs})`}
          render={(params) => {
            const { location, match } = params;
            const pathLang = location.pathname
              .replace(new RegExp(`^/${match.params.lang}`), "")
              .replace(/[/]*$/, "");
            return (
              <Redirect
                to={`/${language}${pathLang}${location.search}`}
                push={false}
              />
            );
          }}
        />
        <Route
          path="*"
          render={(params) => {
            const { location } = params;
            const preparedPathname = location.pathname.replace(/[/]*$/, "");
            return (
              <Redirect
                to={`/${language}${preparedPathname}${location.search}`}
              />
            );
          }}
        />
      </Switch>
    </Router>
  );
};

export default LocalizedRouter;

LocalizedRouter.propTypes = {
  children: PropTypes.node.isRequired,
};
