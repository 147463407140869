const agreementTextStyles = {
  root: {
    whiteSpace: "pre-line",
    "& > p": {
      marginBottom: "35px",
      fontWeight: "500",
      "&:last-child": {
        marginBottom: "0",
      },
    },
  },
};

export default agreementTextStyles;
