import { cardTitle } from "assets/jss/material-kit-pro-react.js";

const sectionBlogInfoStyle = {
  section: {
    paddingTop: "30px",
    paddingBottom: "0",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "70px 0",
  },
  blogTags: {
    marginTop: "8px",
  },
  buttons: {
    marginTop: "0",
    marginBottom: "0",
    float: "right !important",
  },
  card: {
    marginTop: "0",
    textAlign: "left",
  },
  cardTitle: {
    ...cardTitle,
    color: "rgba(60, 72, 88, 1) !important",
  },
  description: {
    fontSize: "1rem",
    color: "rgba(153, 153, 153, 1)",
    fontWeight: 500,
  },
  pullRight: {
    marginTop: "25px",
    float: "right",
  },
};

export default sectionBlogInfoStyle;
