import React, { useMemo } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.js";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/de";
import "moment/locale/ru";
import "moment/locale/uk";
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";
import { Markup } from "interweave";

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText(props) {
  const { post } = props;
  const classes = useStyles();
  const imgClasses = classNames(classes.imgFluid, classes.customImageStyle);
  const { i18n } = useTranslation();
  const resolver = new RichTextResolver();

  const title = post.content.title || "";
  const date = useMemo(() => {
    if (post.created_at) {
      const m = moment(post.created_at).locale(i18n.language);
      return m.format("LL");
    }
    return "";
  }, [i18n.language, post.created_at]);
  const image = post.content.image;
  const text = resolver.render(post.content.long_text);

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          {!!title && <h3 className={classes.title}>{title}</h3>}
          {!!date && (
            <div className={classes.date}>
              <span>{date}</span>
            </div>
          )}
          {!!text && (
            <div className={classes.text}>
              <Markup content={text} />
            </div>
          )}
        </GridItem>
        {!!image && (
          <GridItem xs={12} sm={8} md={6} className={classes.imageSection}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <img src={image} alt={title} className={imgClasses} />
              </GridItem>
            </GridContainer>
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}

SectionText.propTypes = {
  post: PropTypes.object,
};
