import { gql } from "apollo-boost";

export const PostQuery = gql`
  query PostItem($id: ID!, $language: String) {
    PostItem(id: $id, language: $language) {
      content {
        author {
          id
        }
        image
        intro
        long_text
        title
      }
      lang
      created_at
      id
      # slug
    }
  }
`;

export default PostQuery;
