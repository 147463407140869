import React from "react";
import homeBgSrc from "assets/img/landing-page-bg.jpg";
import Parallax from "components/Parallax/Parallax.js";
import { basicNavAnchors } from "constants/basicNavAnchors";
import styles from "assets/jss/material-kit-pro-react/views/sectionsSections/lHomeStyle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const SectionHome = () => {
  const classes = useStyles();

  return (
    <div id={basicNavAnchors.HOME}>
      <Parallax
        className={classes.hero}
        id="parallax"
        image={homeBgSrc}
      ></Parallax>
    </div>
  );
};

export default SectionHome;
