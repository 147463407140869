const storeButtonsStyle = (theme) => ({
  storeButtons: {
    paddingTop: "70px",
  },
  storeButton: {
    marginRight: "10px",
    "& img": {
      width: "150px",
      height: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "110px",
      },
    },
  },
});

export default storeButtonsStyle;
