import { lTextColors } from "assets/jss/material-kit-pro-react.js";

const lInfoStyle = (theme) => ({
  infoSection: {
    padding: "80px 0 120px 0",
  },
  text: {
    textAlign: "center",
    color: lTextColors[0],
    fontSize: "25px",
    maxWidth: "850px",
    margin: "0 auto",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    },
  },
  centerIcon: {
    display: "flex",
    justifyContent: "center",
  },
});

export default lInfoStyle;
