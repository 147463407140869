import React from "react";
import PrivacyPolicyPage from "views/PrivacyPolicyPage/PrivacyPolicyPage";
import ImprintPage from "views/ImprintPage/ImprintPage";
import TermsOfServicePage from "views/TermsOfServicePage/TermsOfServicePage";
import { basicAppRoutes } from "constants/baseAppRoutes";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { appTheme } from "theme";
import { InMemoryCache } from "apollo-cache-inmemory";
import { STORYBLOK_URL, STORYBLOK_API_KEY } from "constants/settings";
import LandingPage from "views/LandingPage/LandingPage.js";
import { Route, Switch } from "react-router";
import BlogPostPage from "views/BlogPostPage/BlogPostPage.js";
import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.js";
import LXMetaTags from "components/LXMetaTags/LXMetaTags";
import LocalizedRouter from "components/LocalizedRouter/LocalizedRouter";

const client = new ApolloClient({
  uri: STORYBLOK_URL,
  request: (operation) => {
    operation.setContext({
      headers: {
        token: STORYBLOK_API_KEY,
        version: "published",
      },
    });
  },
  cache: new InMemoryCache(),
});

const {
  HOME,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  LEGAL_NOTICE,
  BLOG,
  BLOG_WITH_CATEGORY,
  BLOG_POST,
} = basicAppRoutes;

const App = () => {
  return (
    <>
      <LXMetaTags />
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={appTheme}>
          <LocalizedRouter>
            <Switch>
              <Route path={PRIVACY_POLICY} component={PrivacyPolicyPage} />
              <Route path={LEGAL_NOTICE} component={ImprintPage} />
              <Route path={TERMS_OF_SERVICE} component={TermsOfServicePage} />
              <Route path={BLOG_POST} component={BlogPostPage} />
              <Route
                path={[BLOG_WITH_CATEGORY, BLOG]}
                component={BlogPostsPage}
              />
              <Route path={HOME} component={LandingPage} />
            </Switch>
          </LocalizedRouter>
        </MuiThemeProvider>
      </ApolloProvider>
    </>
  );
};

export default App;
