const sectionPostNotFoundStyle = {
  postNotFound: {
    padding: "300px 0",
    fontSize: "40px",
    textAlign: "center",
    fontWeight: 600,
  },
  goBackBtnWrap: {
    paddingTop: "30px",
  },
};

export default sectionPostNotFoundStyle;
