import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-pro-react/components/lFooterStyle";
import { makeStyles } from "@material-ui/core/styles";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { basicAppRoutes } from "../../constants/baseAppRoutes";
import { useRoutePath } from "hooks/useRoutePath";

const useStyles = makeStyles(styles);
const currentYear = new Date().getFullYear().toString();

const LFooter = () => {
  const classes = useStyles();
  const { t } = useTranslation(Namespaces.Common);
  const routePath = useRoutePath();

  const { BLOG, TERMS_OF_SERVICE, PRIVACY_POLICY, LEGAL_NOTICE } = basicAppRoutes;

  return (
    <Footer
      className={classes.footer}
      content={
        <div className={classes.footerInner}>
          <div className={classes.rights}>
            &copy;{" "}
            {t("footer.rights", {
              year: currentYear,
            })}
          </div>
          <div>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <Link to={routePath(BLOG)} className={classes.block}>
                  {t("footer.navigation.blog")}
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link
                  to={routePath(TERMS_OF_SERVICE)}
                  className={classes.block}
                >
                  {t("footer.navigation.terms")}
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to={routePath(PRIVACY_POLICY)} className={classes.block}>
                  {t("footer.navigation.privacy_policy")}
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to={routePath(LEGAL_NOTICE)} className={classes.block}>
                  {t("footer.navigation.imprint")}
                </Link>
              </ListItem>
            </List>
          </div>
        </div>
      }
    />
  );
};

export default LFooter;
