export const APP_DEFAULT_LANGUAGE = "de";

export const BLOG_LOAD_MORE_STEP = 6;
export const BLOG_CARD_PARAGRAPH_LIMIT = 150;
export const HOME_PAGE_BLOG_POSTS_LIMIT = 2;

export const STORYBLOK_API_KEY = process.env.REACT_APP_STORYBLOK_API_KEY;
export const STORYBLOK_URL = process.env.REACT_APP_STORYBLOK_URL;

export const PROD_URL = process.env.REACT_APP_PROD_URL;
