import {
  title,
  cardTitle,
  coloredShadow,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";

const sectionInterestedStyle = {
  title,
  cardTitle,
  coloredShadow,
  textCenter: {
    textAlign: "center",
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "70px 0",
  },
  description: {
    color: grayColor[0],
  },
  image: {
    height: "235px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "cover",
    borderRadius: "6px",
  },
};

export default sectionInterestedStyle;
