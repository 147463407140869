import common from "translations/common/uk.json";
import home from "translations/home/uk.json";
import privacyPolicy from "translations/privacyPolicy/uk.json";
import imprint from "translations/imprint/uk.json";
import termsOfService from "translations/termsOfService/uk.json";
import blog from "translations/blog/uk.json";

export const translationsUK = {
  common,
  home,
  privacyPolicy,
  imprint,
  termsOfService,
  blog,
};
