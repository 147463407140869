import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { basicAppRoutes } from "constants/baseAppRoutes";
import { Link } from "react-router-dom";
import { BLOG_CARD_PARAGRAPH_LIMIT } from "constants/settings";
import Card from "components/Card/Card.js";
import useLoadMore from "hooks/useLoadMore";
import { BLOG_LOAD_MORE_STEP } from "constants/settings";
import Button from "components/CustomButtons/Button.js";
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.es";
import { Markup } from "interweave";
import { Fragment } from "react";
import { getCategoryStyle } from "utils/getCategoryStyle";
import { useRoutePath } from "hooks/useRoutePath";
import { generateUrlSlug } from "utils/generateUrlSlug";
import { baseAppRoutesConfig } from "constants/baseAppRoutes";

const useStyles = makeStyles(sectionInterestedStyle);
const { pathParams } = baseAppRoutesConfig;

export default function SectionInterested(props) {
  const { topPosts } = props;
  const { t } = useTranslation(Namespaces.Blog);
  const classes = useStyles();
  const resolver = new RichTextResolver();
  const routePath = useRoutePath();

  const [blogPostsLimit, visiblePosts, loadMore] = useLoadMore({
    postsLength: topPosts.length,
    step: BLOG_LOAD_MORE_STEP,
    inARow: 3,
  });

  const isLoadMoreVisible =
    topPosts.length + blogPostsLimit > visiblePosts + blogPostsLimit;

  return (
    <div className={classes.section}>
      <h3 className={classes.title + " " + classes.textCenter}>
        {t("interests.title")}
      </h3>
      <br />
      <GridContainer>
        {topPosts.slice(0, visiblePosts).map((p) => {
          const { id, content } = p;
          const { title, image } = content;
          const category = content.category.map((c, i) => (
            <span key={i} style={getCategoryStyle(c)}>
              {t(`categories.${c}`)}
            </span>
          ));
          const link = routePath(basicAppRoutes.BLOG_POST, {
            [pathParams.postSlug]: generateUrlSlug(`${id} ${title}`),
          });
          const text =
            resolver
              .render(content.long_text)
              .slice(0, BLOG_CARD_PARAGRAPH_LIMIT) + "...";

          return (
            <GridItem key={p.id} xs={12} sm={4} md={4} lg={4} xl={4}>
              <Card plain blog>
                <CardHeader image plain>
                  <Link to={link}>
                    <div
                      style={{
                        backgroundImage: `url(${image})`,
                      }}
                      className={classes.image}
                      alt={title}
                    ></div>
                  </Link>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${image})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <h6>
                    {category.map((c, i) => {
                      if (category.indexOf(c) !== category.length - 1)
                        return <Fragment key={i}>{c}, </Fragment>;
                      return c;
                    })}
                  </h6>
                  <h4 className={classes.cardTitle}>
                    <Link to={link}>{title}</Link>
                  </h4>
                  <div className={classes.description}>
                    <Markup content={text} />
                    <Link to={link}>{t("posts.post.read_more")}</Link>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
      {isLoadMoreVisible && (
        <GridContainer className="align-center">
          <Button onClick={loadMore} round color="secondary">
            {t("posts.load_more")}
          </Button>
        </GridContainer>
      )}
    </div>
  );
}

SectionInterested.propTypes = {
  topPosts: PropTypes.arrayOf(PropTypes.object),
};
