import {
  secondaryColor,
  lTextColors,
} from "assets/jss/material-kit-pro-react.js";

const downloadButtonStyle = {
  icon: {
    color: secondaryColor[0],
    marginRight: "10px",
  },
  text: {
    textTransform: "capitalize",
    color: lTextColors[0],
  },
};

export default downloadButtonStyle;
