const liilixLogoStyle = () => ({
  logo: {
    minWidth: "140px",
    maxWidth: "140px",
    height: "auto",
    "& img": {
      width: "100%",
      height: "auto",
    },
  },
});
export default liilixLogoStyle;
