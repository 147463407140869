import React from "react";

const HeadphonesIcon = () => {
  return (
    <svg viewBox="0 0 32 26">
      <path d="M16 9.6c-1.088 0-6.208 0.816-6.384 6.208 2.752-0.64 4.896-2.832 5.44-5.616 0.848 1.84 3.136 4.48 7.088 4.16-0.72-2.736-3.184-4.752-6.144-4.752z"></path>
      <path d="M13.2 17.2c0 0.663-0.537 1.2-1.2 1.2s-1.2-0.537-1.2-1.2c0-0.663 0.537-1.2 1.2-1.2s1.2 0.537 1.2 1.2z"></path>
      <path d="M21.2 17.2c0 0.663-0.537 1.2-1.2 1.2s-1.2-0.537-1.2-1.2c0-0.663 0.537-1.2 1.2-1.2s1.2 0.537 1.2 1.2z"></path>
      <path d="M25.6 16c0-5.296-4.304-9.6-9.6-9.6s-9.6 4.304-9.6 9.6h-1.6v6.4h3.2v-6.4c0-4.416 3.584-8 8-8s8 3.584 8 8v8h-9.6v1.6h11.2v-3.2h1.6v-6.4h-1.6z"></path>
    </svg>
  );
};

export default HeadphonesIcon;
