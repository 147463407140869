import React from "react";
import LHeaderLinks from "components/LHeaderLinks/LHeaderLinks";
import LiilixLogo from "components/LiilixLogo/LiilixLogo";
import Header from "components/Header/Header.js";
import LFooter from "components/LFooter/LFooter.js";
import classNames from "classnames";
import infoPagesStyles from "assets/jss/material-kit-pro-react/views/infoPagesStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";
import AgreementText from "components/AgreementText/AgreementText";

const useStyles = makeStyles(infoPagesStyles);

export default function ImprintPage() {
  const classes = useStyles();
  const { t } = useTranslation(Namespaces.Imprint);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <div>
      <Header
        fixed
        brand={<LiilixLogo />}
        color="transparentBlack"
        changeColorOnScroll={{
          height: 40,
          color: "white",
        }}
        links={<LHeaderLinks />}
      />
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <div className={classNames(classes.pageTitle, classes.pb80)}>
            {t("page_title")}
          </div>
          <div className={classes.pageContent}>
            <AgreementText
              paragraphs={t("imprint_paragraphs", {
                returnObjects: true,
              })}
            />
          </div>
        </div>
      </div>
      <LFooter />
    </div>
  );
}
