const lHomeStyle = {
  hero: {
    "@media screen and (max-width: 960px)": {
      height: "50vh",
    },

    "@media screen and (max-width: 530px)": {
      height: "40vh",
    },
  },
};

export default lHomeStyle;
