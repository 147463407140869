import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import LFooter from "components/LFooter/LFooter.js";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";
import SectionService from "./Sections/SectionService.js";
import SectionDownload from "./Sections/SectionDownload.js";
import SectionContacts from "./Sections/SectionContacts.js";
import SectionPricing from "./Sections/SectionPricing.js";
import SectionInfo from "./Sections/SectionInfo.js";
import SectionBlogs from "./Sections/SectionBlogs.js";
import SectionHome from "./Sections/SectionHome.js";
import LHeaderLinks from "components/LHeaderLinks/LHeaderLinks";
import LiilixLogo from "components/LiilixLogo/LiilixLogo";
import { basicNavAnchors } from "../../constants/basicNavAnchors";
import { HOME_PAGE_BLOG_POSTS_LIMIT } from "constants/settings";
import { useQuery } from "react-apollo";
import LatestPostsQuery from "gql/queries/LatestPostsQuery";
import { Languages } from "constants/appLanguages";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import cx from "classnames";
import usePrevious from "hooks/usePrevious";

const useStyles = makeStyles(landingPageStyle);

export default function LandingPage() {
  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1,
    );
    if (
      window.location.href.lastIndexOf("#") > 0 &&
      Object.values(basicNavAnchors).lastIndexOf(href) !== -1
    ) {
      document.getElementById(href).scrollIntoView();
    } else {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }
  }, []);

  const classes = useStyles();
  const { i18n } = useTranslation();

  const currentLang = i18n.language.split("-")[0];
  const queryLang = currentLang === Languages.De ? "" : `${currentLang}/*`;

  const latestPostsQuery = useQuery(LatestPostsQuery, {
    variables: { starts_with: queryLang, per_page: HOME_PAGE_BLOG_POSTS_LIMIT },
  });

  const isFetching = latestPostsQuery.loading;
  const latestPosts = latestPostsQuery.data?.PostItems.items || [];

  const prevLang = usePrevious(i18n.language);

  useEffect(() => {
    if (prevLang !== i18n.language) {
      latestPostsQuery.refetch();
    }
  }, [i18n.language, latestPostsQuery, prevLang]);

  return (
    <div>
      <Header
        fixed
        brand={<LiilixLogo />}
        color="transparentBlack"
        changeColorOnScroll={{
          height: 300,
          color: "white",
        }}
        links={<LHeaderLinks />}
      />
      <SectionHome />
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <SectionService />
          <SectionDownload />
        </div>
        <SectionPricing />
        <div className={classes.container}>
          <SectionInfo />
          {isFetching ? (
            <div className={cx(classes.loader, "align-center")}>
              <CircularProgress color="primary" />
            </div>
          ) : latestPosts.length > 0 ? (
            <SectionBlogs latestsPosts={latestPosts} />
          ) : null}
        </div>
        <SectionContacts />
      </div>
      <LFooter />
    </div>
  );
}
