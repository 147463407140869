import {
  sectionGutter,
  container,
  whiteColor,
  lTextColors,
} from "assets/jss/material-kit-pro-react.js";

const lPricingStyle = (theme) => ({
  container: {
    color: whiteColor,
    ...container,
    zIndex: "2",
  },
  pricingSection: {
    ...sectionGutter,
    paddingTop: "120px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0",
    },
  },
  pricingInner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  img: {
    height: "550px",
    width: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hLarge: {
    height: "350px !important",
  },
  priceBlock: {
    width: "50%",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontSize: "40px",
    fontWeight: "600",
    marginBottom: "10px",
    marginTop: 0,
  },
  text: {
    marginBottom: "30px",
    maxWidth: "300px",
    margin: "0 auto",
  },
  priceMain: {
    marginBottom: "10px",
    color: lTextColors[0],
    "& span": {
      fontSize: "35px",
      fontWeight: "600",
    },
  },
  priceListItem: {
    maxWidth: "200px",
    color: lTextColors[0],
    margin: "0 auto",
    fontSize: "13px",
    padding: "7px 0",
    borderBottom: "1px solid #ccc",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
});

export default lPricingStyle;
