import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { prepareRoutePath } from "utils/prepareRoutePath";

export function useRoutePath() {
  const { i18n } = useTranslation();

  return useCallback(
    (path, params = {}, query = "") =>
      prepareRoutePath(
        path,
        { lang: i18n.languages[0].split("-")[0], ...params },
        query,
      ),
    [i18n.languages],
  );
}
