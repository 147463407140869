import blogCategories from "constants/blogCategories";
import {
  secondaryColor,
  successColor,
} from "assets/jss/material-kit-pro-react.js";

const blogCategoryColors = {
  [blogCategories.GettingStarget]: "#981535",
  [blogCategories.AppFeatures]: secondaryColor[0],
  [blogCategories.Business]: successColor[0],
  __fallback: successColor[0],
};

export default blogCategoryColors;
