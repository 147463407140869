import React from "react";
import sectionPostNotFoundStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionPostNotFoundStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import { basicAppRoutes } from "constants/baseAppRoutes";
import { Link } from "react-router-dom";
import { Namespaces } from "i18n";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(sectionPostNotFoundStyle);

const SectionPostNotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation(Namespaces.Blog);

  return (
    <div className={classes.postNotFound}>
      <div>{t("post.not_found")}</div>
      <div className={classes.goBackBtnWrap}>
        <Link to={basicAppRoutes.BLOG}>
          <Button round color="primary">
            {t("post.go_to_blog")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default SectionPostNotFound;
