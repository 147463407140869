export const baseAppRoutesConfig = {
  pathParams: {
    lang: ":lang",
    postSlug: ":url_slug",
  },
};

const { lang, postSlug } = baseAppRoutesConfig.pathParams;

export const basicAppRoutes = {
  HOME: `/${lang}`,
  TERMS_OF_SERVICE: `/${lang}/terms-of-service`,
  PRIVACY_POLICY: `/${lang}/privacy-policy`,
  LEGAL_NOTICE: `/${lang}/legal-notice`,
  BLOG: `/${lang}/blog-posts`,
  BLOG_WITH_CATEGORY: `/${lang}/blog-posts/:category?`,
  BLOG_POST: `/${lang}/blog-post/${postSlug}`,
};
